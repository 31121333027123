<template>
  <UserLayout
  :title="title"
  :items="securityUsers"
  :is-admin="false"
  />
</template>

<script>
import UserLayout from '@/components/views/Users/VUserLayout'
import { securityUsers } from '@/utils/tabs'

export default {
  props: {
    title: {
      type: String,
      required: false
    }
  },
  setup () {
    return {
      securityUsers
    }
  },
  components: {
    UserLayout
  }
}
</script>
